<template>
  <div>
    <el-row class="m-0" :gutter="20">
      <el-col v-if="userCanEdit" :span="3">
        <el-button @click="handleCreate" type="text" icon="el-icon-circle-plus-outline" >Добавить</el-button>
      </el-col>
      <el-col :span="3">
      <el-button @click="handleDownLoad" type="text"
                  :loading="loading"
                   icon="el-icon-download">Выгрузить
        </el-button>
      </el-col>
      <el-col :span="12">
        <pagination
          style="text-align: center"
          :total-count="totalCount"
          :page-size.sync="pageSize"
          :current-page.sync="pageNumber"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </el-col>
    </el-row>
    <div>
      <div>
        <el-button @click="toggleNodes" type="text" class="ml-2">Свернуть/развернуть всё</el-button>
        <span class="total_items">Всего: {{this.staffCulpabilitiesCount}}</span>
      </div>
      <el-table
        ref="table"
        v-loading="loading"
        style="width: 100%"
        row-key="id"
        :height="tableHeight"
        :data="staffCulpabilities"
        :default-sort = "{prop: 'name', order: 'ascending'}"
        @sort-change="handleSortChange"
      >
        <el-table-column v-if="userCanEdit" label="" width="70" align="right">
          <template slot-scope="scope">
              <el-button v-if="!scope.row.parentId" type="text" icon="el-icon-edit"
                        @click="handleEdit(scope.$index, scope.row)"></el-button>
          </template>
        </el-table-column>
        <el-table-column
          prop="name"
          label="Наименование"
          sortable="custom"
          >
          <template slot-scope="scope">
            <div style="display:inline-flex;">
                <el-button v-if="scope.row.parentId && userCanEdit" style="margin-right:.8rem" type="text" icon="el-icon-edit"
                          @click="handleEdit(scope.$index, scope.row)"></el-button>
              <div>{{scope.row.name}}</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="code"
          label="Код"
          sortable="code"
          header-align="center"
          align="center"
          width="100">
        </el-table-column>
        <el-table-column
          prop="actual"
          label="Признак актуальности"
          sortable="custom"
          width="220"
          header-align="center"
          align="center"
          :formatter="boolRenderer">
        </el-table-column>
        <el-table-column v-if="userCanEdit" label="" width="50">
            <template slot-scope="scope">
                  <el-button type="text" icon="el-icon-delete"
                            @click="handleDelete(scope.$index, scope.row)"></el-button>
            </template>
          </el-table-column>
      </el-table>
    </div>
    <staff-culpability-modal @on-update="getStaffCulpabilities()" @on-create="getStaffCulpabilities()" v-model="dialogVisible" :staffCulpability="dialogStaffCulpability"></staff-culpability-modal>
  </div>
</template>

<script>
import cloneDeep from 'lodash.clonedeep';
import { mapGetters } from 'vuex';
import staffCulpabilitiesApi from '@/api/nsi/staffCulpabilities';
import pagination from '@/components/Ui/Pagination';
import formatters from '@/mixins/formatters.js';
import tableResize from '@/mixins/tableResize.js';
import xlsUpDownHelper from '@/mixins/xlsUpDownHelper';
import StaffCulpabilityModal from './StaffCulpabilityModal';

export default {
  name: 'StaffCulpabilitiesTable',
  props: {
    filterModel: { type: Object, default: () => {} },
    filterActive: { type: Boolean, default: () => true },
  },
  mixins: [formatters, tableResize, xlsUpDownHelper],
  components: { pagination, StaffCulpabilityModal },
  data() {
    return {
      loading: false,
      staffCulpabilities: [],
      pageNumber: 1,
      pageSize: 20,
      totalCount: 0,
      sortField: 'name',
      sortDesc: false,
      dialogVisible: false,
      dialogStaffCulpability: {},
      staffCulpabilitiesCount: 0
    };
  },
  computed: {
    ...mapGetters('identity', ['userHasRole']),
    userCanEdit() {
      return this.userHasRole('NsiLocalEdit,NsiFullAccess');
    },
  },
  watch: {
    filterModel: {
      handler() {
        this.getStaffCulpabilities();
      },
      deep: true
    },
  },
  async mounted() {
    this.$nextTick(() => {
      this.tableHeight = this.getTableHeight();
    });
    await this.getStaffCulpabilities();
    this.tableHeight = this.getTableHeight();
  },
  methods: {
    async handleDownLoad() {
      this.loading = true;
      const { table } = this.$refs;
      const tableColumns = table.store.states.columns;
      const res = await staffCulpabilitiesApi.getStaffCulpabilitiesWithPagination(
          this.pageNumber,
          this.totalCount,
          this.filterModel.nameFilter,
          this.sortField,
          this.sortDesc
      );
      if (res.data) {
        await this.exportToExcel('Организационные причины', res.data.items, tableColumns);
      }
      this.loading = false;
    },
    async getStaffCulpabilities() {
      this.loading = true;
      const res = await staffCulpabilitiesApi.getStaffCulpabilitiesWithPagination(
          this.pageNumber,
          this.pageSize,
          this.filterModel.nameFilter,
          this.sortField,
          this.sortDesc
      );
      if (res.data) {
        this.staffCulpabilities = res.data.items;
        this.totalCount = res.data.totalCount;
        this.staffCulpabilitiesCount = res.data.treeItemsCount;
      }
      this.loading = false;
    },
    async handleSizeChange() {
      this.pageNumber = 1;
      await this.getStaffCulpabilities();
    },
    async handleCurrentChange() {
      await this.getStaffCulpabilities();
    },
    async handleSortChange(event) {
        this.sortField = event.prop;
        this.sortDesc = event.order === 'descending';
        await this.getStaffCulpabilities();
    },
    handleEdit(index, staffCulpability) {
      this.dialogStaffCulpability = cloneDeep(staffCulpability);
      this.dialogVisible = true;
    },
    // eslint-disable-next-line no-unused-vars
    handleCreate(index, staffCulpability) {
      this.dialogStaffCulpability = {
        id: null, name: null, code: null, parentId: null
      };
      this.dialogVisible = true;
    },
    handleDelete(index, staffCulpability) {
      this.$confirm('Вы действительно хотите удалить запись?', 'Удаление записи!', {
        confirmButtonText: 'ОК',
        cancelButtonText: 'Отмена',
        type: 'warning',
      }).then(async () => {
        this.loading = true;
        const res = await staffCulpabilitiesApi.deleteStaffCulpability(staffCulpability.id);
          if (!res.data.succeeded) {
            this.$alert(res.data.resultMessage, 'Ошибка', {
              confirmButtonText: 'OK',
              type: 'warning',
            });
          } else {
            await this.getStaffCulpabilities();
          }
        this.loading = false;
      });
    },
    toggleNodes() {
      const expandNodes = [];
      const treeKeys = Object.keys(this.$refs.table.store.$data.states.treeData);
      treeKeys.forEach((i) => {
        expandNodes.push(this.$refs.table.store.$data.states.treeData[i].expanded);
      });
      if (expandNodes.includes(false)) {
        this.staffCulpabilities.forEach((sc) => { this.$refs.table.toggleRowExpansion(sc, true); });
      } else {
        this.staffCulpabilities.forEach((sc) => { this.$refs.table.toggleRowExpansion(sc, false); });
      }
    }
  }
};
</script>

<style scoped>
.el-button{
  vertical-align: middle;
}
.total_items {
   float: right;
   color: #909399;
   font-family: Montserrat-Regular;
   font-size: 14px;
   margin-top: 12px;
   margin-right: 8px;
}
</style>
